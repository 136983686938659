@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  padding: 0;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
}

#root {
  display: flex;
  justify-content: center;
}

.fade-top {
  position: absolute;
  bottom: 0;
  z-index: -9;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    115deg,
    rgba(255, 255, 255, 1) 40%,
    rgb(255 255 255 / 46%) 100%
  );
}

@media (min-width: 769px) {
  .fade-top {
    background: linear-gradient(
      115deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

li {
  line-height: 20px;
}

button {
  font-family: 'Raleway', sans-serif;
}
