.carousel-root .carousel-status {
  @apply absolute;
  @apply top-auto;
  @apply bottom-0;
  @apply left-0;
  @apply text-center;
  @apply text-gray-500;
  @apply text-base;
  @apply font-bold;

  text-shadow: none;
}

.carousel.carousel-slider {
  @apply pb-10;
}
